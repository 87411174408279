import React from "react";

/**
 * TODO
 * Clone footer from calculator
 */
function Footer() {
    return (
        <>
            <div className="section-10 wf-section footer">
                <div className="app-container">
                    <div className="footer-top-bar">
                        <div className="div-block-29 footer-links">
                            <a
                                href="https://revenueroll.com/"
                                aria-current="page"
                                className="footer-link"
                            >
                                Home
                            </a>
                            <a href="https://revenueroll.com/products" className="footer-link">
                                Products
                            </a>
                            <a href="https://revenueroll.com/questionnaire" className="footer-link">
                                Calculator
                            </a>
                            <a
                                href="https://revenueroll.com/partners-advisors"
                                className="footer-link"
                            >
                                Partners &amp; Advisors
                            </a>
                            <a href="https://revenueroll.com/about-us" className="footer-link">
                                About Us
                            </a>
                        </div>
                        <div
                            id="w-node-_7ae7b114-0906-a8fe-580b-6918a438477e-a438476e"
                            className="footer-top-div-2"
                        >
                            <div className="div-block-34"></div>
                            <a href="https://revenueroll.com/terms" className="footer-link">
                                Terms
                            </a>
                            <a
                                href="https://revenueroll.com/privacy-policy"
                                className="footer-link"
                            >
                                Privacy
                            </a>
                            <div className="social-links">
                                <a
                                    href="https://www.linkedin.com/company/revenueroll/about/"
                                    target="_blank"
                                    className="w-inline-block"
                                    rel="noreferrer"
                                >
                                    <img
                                        src="https://assets.website-files.com/62a5c3520a53a278ac0304db/62a5d94b21acef30a02fdc71_Group%203592.svg"
                                        loading="lazy"
                                        alt="LinkedIn"
                                        className="image-19"
                                    />
                                </a>
                                <a
                                    href="https://www.facebook.com/Revenue-Roll-104606132176982/?ref=page_internal"
                                    target="_blank"
                                    className="w-inline-block"
                                    rel="noreferrer"
                                >
                                    <img
                                        src="https://assets.website-files.com/62a5c3520a53a278ac0304db/62a5d94b21acef63482fdc72_Group%203590.svg"
                                        loading="lazy"
                                        alt="Facebook"
                                        className="image-19"
                                    />
                                </a>
                                <a
                                    href="https://twitter.com/GetRevRoll"
                                    target="_blank"
                                    className="w-inline-block"
                                    rel="noreferrer"
                                >
                                    <img
                                        src="https://assets.website-files.com/62a5c3520a53a278ac0304db/62a5d94b21acef73152fdc73_Group%203590%20(1).svg"
                                        loading="lazy"
                                        alt="Twitter"
                                        className="image-19"
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/getrevroll/"
                                    target="_blank"
                                    className="w-inline-block"
                                    rel="noreferrer"
                                >
                                    <img
                                        src="https://assets.website-files.com/62a5c3520a53a278ac0304db/62a5d94b21acef94fc2fdc74_Group%203590%20(2).svg"
                                        loading="lazy"
                                        alt="Instagram"
                                        className="image-19"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="separator desktop"></div>
                    <div className="footer-bottom-div">
                        <img
                            src="https://assets.website-files.com/62a5c3520a53a278ac0304db/62a5d94b21acef02bb2fdc75_Auto%20Layout%20Horizontal%20(5).svg"
                            loading="lazy"
                            alt="Revenue Roll Logo"
                            className="image-20"
                        />
                        <div className="separator mobile"></div>
                        <div className="div-block-26 locations-div">
                            <div className="text-block-17">New York, NY</div>
                            <div className="text-block-17">Miami, FL</div>
                            <div className="text-block-17 no-margin">Los Angeles, CA</div>
                        </div>
                        <div className="div-block-33 copyrights-div">
                            <div className="text-block-19">©Revenue Roll</div>
                            <div className="text-block-19">All Rights Reserved</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
