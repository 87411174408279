import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Header from "./components/Header";
import ErrorMail from "./components/ErrorMail";
import Footer from "./components/Footer";

const Passwordless = () => {
    const [status, setStatus] = useState(true);
    let history = useHistory();
    const [errroMessage, setErrroMessage] = useState("Something went wrong!");

    const getUser = async (access_token) => {
        try {
            const result = await axios.get(process.env.REACT_APP_GET_USER_URL, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            let { sub } = result.data;
            const userid = sub.split("|")[1];
            console.log(userid);

            if (userid.length < 5) {
                setStatus(false);
            }

            const data = {
                key: userid,
            };

            const store = await axios.post(process.env.REACT_APP_STORE_KEY_URL, data);
            console.log(store.data);
            localStorage.setItem("userid", userid);
            history.push("/");
        } catch (error) {
            console.log(error);
            console.log(errroMessage);
            setStatus(false);
        }
    };

    useEffect(() => {
        let baseUrl = window.location.href;
        baseUrl = baseUrl.replace("#", "?");
        let url = new URL(baseUrl);
        let params = new URLSearchParams(url.search.split("?").join('&'));

        const access_token = params.get("access_token");
        const unauthorized = params.get("error");
        const email = params.get("email");
        localStorage.setItem("email", email);
        if (unauthorized) {
            setStatus(false);
            setErrroMessage("Email link expired.");
        }

        getUser(access_token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            <div className="pt-5">
                {status ? "Redirecting to Integry. Do not close this tab." : <ErrorMail />}
            </div>

            <Footer />
        </>
    );
};

export default Passwordless;
