import Header from "./components/Header";

const ThankYou = () => {
  return (
      <>
      <Header />

    <div className="pt-20">
      <div
        className={"2xl:w-826 w-340 md:w-517 xl:w-779 relative mt-16 mx-auto"}
        style={{ background: "#ecf6ff" }}
      >
        <div className={"absolute w-full left-0 flex justify-center -top-16"}>
          <AnimatedCheck />
        </div>
        <p
          style={{ color: "#0e497b" }}
          className="pt-16 pb-8 xl:text-3xl text-center tracking-wider font-bold text-xl"
        >
          Thank you. <br/>
          <span className="text-xl">A link has been sent to your mail.</span>
        </p>
        
      </div>
    </div>
  
    </>
  );
};

export default ThankYou;

const AnimatedCheck = () => {
    return (
        <div className="swal-icon swal-icon--success overflow-hidden">
            <span className="swal-icon--success__line swal-icon--success__line--long"></span>
            <span className="swal-icon--success__line swal-icon--success__line--tip"></span>

            <div className="swal-icon--success__ring" style={{background: '#a5dc86'}}></div>
            <div className="swal-icon--success__hide-corners"></div>
        </div>
    )
}


