import React, { useState } from "react";

import openMenuIcon from "../assets/menu-open.svg";
import closeMenuIcon from "../assets/menu-close.svg";

function Header() {
    const [showMobileMenu, setShowMobileMenu] = React.useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

    window.addEventListener("resize", (ev) => {
        setIsMobile(ev.currentTarget.innerWidth <= 1000);
    });

    const toggleMenu = () => setShowMobileMenu((m) => !m);

    return (
        <>
            <div className="container-14 app-container nav-container w-container">
                <a href="http://revenueroll.com" className="brand-2 w-nav-brand">
                    <img
                        width="165"
                        loading="lazy"
                        alt="Revenue Roll"
                        src="https://assets.website-files.com/62a5c3520a53a278ac0304db/62aa0763dd5fc44340218399_Auto%20Layout%20Horizontal%20(6).svg"
                        className="image-21"
                    />
                </a>
                <nav role="navigation" className={`nav-menu-2 w-nav-menu desktop-menu`}>
                    <a href="https://revenueroll.com/" aria-current="page" className="w-nav-link">
                        Home
                    </a>
                    <a href="https://revenueroll.com/products" className="w-nav-link">
                        Products
                    </a>
                    <a href="https://revenueroll.com/questionnaire" className="w-nav-link">
                        Calculator
                    </a>
                    <a href="https://revenueroll.com/partners-advisors" className="w-nav-link">
                        Partners &amp; Advisors
                    </a>
                    <a href="https://revenueroll.com/about-us" className="w-nav-link">
                        About Us
                    </a>
                </nav>
                <a
                    href="http://app.revenueroll.com"
                    className="primarybutton main-menu-btn-desktop w-button"
                >
                    Get Started
                </a>

                <button className="mob-menu-btn" onClick={toggleMenu}>
                    <img src={showMobileMenu ? closeMenuIcon : openMenuIcon} alt="Menu Icon" />
                </button>
            </div>

            <div className={"mobile-menu " + (isMobile && !showMobileMenu ? "hidden" : "")}>
                <nav role="navigation" className={`nav-menu-2 w-nav-menu `}>
                    <a href="https://revenueroll.com/" aria-current="page" className="w-nav-link">
                        Home
                    </a>
                    <a href="https://revenueroll.com/products" className="w-nav-link">
                        Products
                    </a>
                    <a href="https://revenueroll.com/questionnaire" className="w-nav-link">
                        Calculator
                    </a>
                    <a href="https://revenueroll.com/partners-advisors" className="w-nav-link">
                        Partners &amp; Advisors
                    </a>
                    <a href="https://revenueroll.com/about-us" className="w-nav-link">
                        About Us
                    </a>
                </nav>

                <a
                    href="http://app.revenueroll.com"
                    className="primarybutton main-menu-btn-mobile w-button"
                >
                    Get Started
                </a>
            </div>
        </>
    );
}

export default Header;
