import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import ThankYou from "./ThankYou"
import Passwordless from './Passwordless'
import React from 'react'

function Router() {
    return (
      <Switch>
        <Route path={"/passwordless"} exact component={Passwordless} />

        <Route path={"/"} exact>
          <Home />
        </Route>

        <Route path={"/thankyou"} exact>
          <ThankYou />
        </Route>
      </Switch>
    );
}

export default Router
