import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useEffect } from "react";
import { IntegryJS, Helpers } from "@integry/sdk";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const Home = () => {
    useEffect(() => {
        console.log(localStorage.getItem("userid"));

        const init = async () => {
            const appKey = process.env.REACT_APP_INTEGRY_APP_KEY;
            const appSecret = process.env.REACT_APP_INTEGRY_APP_SECRET;
            const userId = localStorage.getItem("userid");
            const deploymentId = process.env.REACT_APP_INTEGRY_DEPLOYEMENT_ID;

            const hash = await Helpers.getAuthHash(userId, appSecret);
            const integryHandle = new IntegryJS({
                appKey,
                hash,
                deploymentId,
                userId,
                xIntegryConfig: {
                    appAuth: {
                        apiKey: localStorage.getItem("userid"),
                    },
                },
            });

            integryHandle.init({
                containerId: "my-sdk-container",
                renderMode: IntegryJS.RenderModes.MODAL,
            });

            console.log(integryHandle);

            integryHandle.eventEmitter.on("did-load-flow", async (data) => {
                console.log(`This Flow is named: ${data.templateTitle}!`);
            });

            integryHandle.eventEmitter.on("did-save-integration", async (data) => {
                console.log(data);
                console.log(`Hey, we set up ${data.name} successfully!`);
                toast.success("Redirecting to calculator", {
                    autoClose: 4000,
                    hideProgressBar: false,
                });
                await redirectToCalculator();
            });

            const redirectToCalculator = async () => {
                let url = process.env.REACT_APP_RR_BASE_URL + "/v2/get-record";
                const headers = {
                    "api-key": localStorage.getItem("userid"),
                    "Content-Type": "application/json",
                };
                const da = {};

                const result = await axios.post(url, da, {
                    headers: headers,
                });
                let resData = result.data?.body[0];
                const email = localStorage.getItem("email");

                let uri = "";
                if (resData?.client_type === "Ecommerce" || resData?.monthly_revenue) {
                    uri += `type=ecommerce`;
                } else if (
                    resData?.client_type === "Event-Driven" ||
                    resData.monthly_event_revenue ||
                    resData.event_worth
                ) {
                    uri += `type=event&e_online_event_monthly=${resData.monthly_event_revenue}&g_event_worth_monthly=${resData.event_worth}`;
                }

                if (email) {
                    uri += `&email=${email}`;
                }

                if (resData?.monthly_revenue) {
                    uri += `&ga_measure_success_pointer=${resData.monthly_revenue}`;
                }
                if (resData?.ga_measure_success_pointer) {
                    uri += `&c_online_revenue_monthly=${resData?.ga_measure_success_pointer}`;
                }

                // console.log(uri);

                window.location.replace(`https://www.revenueroll.com/calculator?${uri}`);
            };
        };

        init();
    }, []);

    return (
        <div>
            <Header />
            <div className="app-container">
                <p className="choose-integration">Choose Where to Import Data From</p>
                <div className="App-embed" id="my-sdk-container" />
            </div>

            <Footer />
            <ToastContainer />
        </div>
    );
};

export default Home;
