import React from "react";

import { AiOutlineCloseCircle } from "react-icons/ai";

function ErrorMail() {
    return (
        <div className="error-component">
            <div className="app-container">
                <div className="error-message">
                    <AiOutlineCloseCircle  className="error-icon"/>
                    <h1 className="error-heading">Error</h1>
                    <div className="error-desc">Your Session has Expired</div>
                    <a href="https://www.revenueroll.com/questionnaire" className="error-action">
                        Return to Sign Up
                    </a>
                </div>
            </div>
            <img
                className="error-bottom-design"
                src="https://assets.website-files.com/62a5c3520a53a278ac0304db/62e59f3276be8a2ae1e40510_Frame%20313460.svg"
                alt=""
            />
        </div>
    );
}
export default ErrorMail;
